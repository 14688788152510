import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Env } from '../../../../utils/env';
import { scrollTop } from '../../../../utils/helpers';

const App: React.FC = React.memo((props) => {
  const location = useLocation();

  useEffect(() => {
    scrollTop();
    //console.log(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    let appTitle: string;
    if (Env.TenantHost === 'AyanAir') {
      appTitle = 'AyanAir';
    } else {
      appTitle = 'itours';
    }

    document.title = appTitle;
  }, []);

  return <>{props.children}</>;
});

export default App;
