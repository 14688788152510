import { Button } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Translations } from '../../../../features/localization';
import { FlightItinerarySummary } from '../../types';
import FlightBookAlternativeModal from '../FlightBookAlternativeModal/FlightBookAlternativeModal';

type FlightBookButtonProps = {
  itinerary: FlightItinerarySummary;
  block?: boolean;
};
const FlightBookOperations: React.FC<FlightBookButtonProps> = (props) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const button = useMemo(() => {
    const button = (
      <Button
        block={props.block}
        danger
        type="primary"
        className={!props.block ? 'min-width-md mx-lg-2' : undefined}
        onClick={() => (props.itinerary.hasAlternative ? setModalVisible(true) : undefined)}>
        {t(Translations.Flight.Book)}
      </Button>
    );
    if (props.itinerary.hasAlternative) {
      return button;
    } else {
      return (
        <Link target="_blank" rel="noopener noreferrer" to={`/flights/flightPI?key=${props.itinerary.key}`}>
          {button}
        </Link>
      );
    }
  }, [props.block, props.itinerary.hasAlternative, props.itinerary.key, t]);

  return (
    <>
      {button}
      {modalVisible && (
        <FlightBookAlternativeModal
          modalProps={{ visible: modalVisible }}
          onCancel={() => setModalVisible(false)}
          itinerary={props.itinerary}
          onContinue={(flightKey) => window.open(`/flights/flightPI?key=${flightKey}`)}
        />
      )}
    </>
  );
};

export default React.memo(FlightBookOperations);
