import ReactDOM from 'react-dom';

import AyanLogo from '../../../../assets/ayanAir-logo.svg';
import Logo from '../../../../assets/itours-logo.svg';
import { Env } from '../../../../utils/env';

const LoadingPortal: React.FC = () => {
  const logo = Env.TenantHost === 'AyanAir' ? AyanLogo : Logo;

  const loadingElement = (
    <div className="loading2Holder">
      <img src={logo} title="itours" alt="itours" width="100" style={{ marginLeft: 0, marginBottom: 0 }} />
      <div className="loading2"></div>
      <br />
      <br />
      <br />
    </div>
  );
  return ReactDOM.createPortal(loadingElement, document.getElementById('loading_portal')! as HTMLDivElement);
};

export default LoadingPortal;
