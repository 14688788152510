import { Checkbox, Col, Row } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../features/hooks';
import { Translations } from '../../../../../../features/localization';
import { useBookingTempFilters } from '../../../../../booking';
import { FilterItemTitle } from '../../../../../shared';
import { tempItinerariesSummarySelector } from '../../../../redux/flight-selectors';
import classes from '../FlightFilter.module.scss';

type Props = {
  isPackage?: boolean;
};
const FlightsFilterCabinClass: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const { filter } = useBookingTempFilters({ moduleType: props.isPackage ? 'Package' : 'Flight' });

  const itineraries = useAppSelector((state) => (props.isPackage ? state.package.temp.packages.response?.flights : tempItinerariesSummarySelector(state)));
  const cabinClasses = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.constants.flights.cabinClasses : state.flight.temp.flights.constants.cabinClasses
  );
  const filteredCabinClasses = useAppSelector((state) =>
    props.isPackage ? state.package.temp.packages.processing.filters.flights.cabinClasses : state.flight.temp.flights.filters.cabinClasses
  );

  const cabinClassesWithCount = useMemo(() => {
    return cabinClasses.map((item) => ({
      name: item.name,
      count: itineraries!.filter((f) => f.cabinClass.name === item.name).length,
    }));
  }, [cabinClasses, itineraries]);

  const handleResetClicked = useCallback(() => {
    filter({ type: 'CabinClass', value: undefined });
  }, [filter]);

  const handleFilterChanged = (checkedValues: CheckboxValueType[]) => {
    const values = checkedValues as string[];
    filter({ type: 'CabinClass', value: values });
  };

  return (
    <>
      <FilterItemTitle
        title={t(Translations.Flight.CabinClass)}
        resetButtonVisibility={filteredCabinClasses && filteredCabinClasses.length > 0}
        onResetClick={handleResetClicked}
      />
      <Checkbox.Group onChange={handleFilterChanged} value={filteredCabinClasses} className="w-100">
        {cabinClassesWithCount.map((item) => (
          <Checkbox key={item.name} value={item.name} className={`${classes.spacedBetween} mx-0`}>
            <Row justify="space-between">
              <Col>{item.name} </Col>
              <Col>
                <span>({item.count})</span>
              </Col>
            </Row>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </>
  );
});

export default FlightsFilterCabinClass;
