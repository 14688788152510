import { Tooltip } from 'antd';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Tooltip as BootstrapTooltip, Dropdown, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DropdownTopbarItemToggler } from '../../../../../_metronic/_partials/dropdowns';
import { LocalStorageKey } from '../../../../../features/constants';
import { useAppSelector, useGlobalization, useMount } from '../../../../../features/hooks';
import { Translations } from '../../../../../features/localization';
import { HtmlDirection } from '../../../../../features/localization/cultures';
import { Env } from '../../../../../utils/env';
import { isDevelopment, setStorage } from '../../../../../utils/helpers';
import { currencySelector, getAffiliateStorage, setCurrencySaga } from '../../../../account';
import { paymentSlice } from '../../../../payment';
import { SupportedCurrencies } from '../../..';

const CurrencySelectorDropdown: React.VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { culture } = useGlobalization();

  const currentCurrency = useAppSelector(currencySelector);
  const canChangeCurrency = useAppSelector((state) => state.payment.canChangeCurrency);

  const affiliate = getAffiliateStorage();

  const changeCurrency = (currency: string) => {
    setStorage(LocalStorageKey.Currency, currency);
    window.location.reload();
  };

  const iranianAffiliate = useMemo(() => {
    return affiliate?.countryCode.toLowerCase() === 'ir';
  }, [affiliate]);

  //forcing iranian affiliates to use IRR currency
  useMount(() => {
    if (Env.TenantHost === 'iTours' && iranianAffiliate && currentCurrency !== 'IRR') {
      dispatch(setCurrencySaga('IRR'));
      window.location.reload();
    }
  });

  //forcing ayan-air to use USD currency
  useMount(() => {
    if (Env.TenantHost === 'AyanAir') {
      dispatch(paymentSlice.actions.changingCurrencyAvailability(false));
      if (currentCurrency !== 'USD') {
        dispatch(setCurrencySaga('USD'));
        window.location.reload();
      }
    }
  });

  return Env.TenantHost === 'iTours' && (isDevelopment() || !iranianAffiliate) ? (
    <Dropdown drop="down" alignRight className={'font-fa'}>
      <Dropdown.Toggle as={DropdownTopbarItemToggler}>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <BootstrapTooltip id="currency-panel-tooltip">
              {canChangeCurrency ? (
                t(Translations.Tooltips.SelectCurrency)
              ) : (
                <span className="text-danger">
                  <span className="fas fa-times-circle" /> {t(Translations.Tooltips.CurrencyCanNotChangeInThisPage)}
                </span>
              )}
            </BootstrapTooltip>
          }>
          <div className="btn btn-outline-secondary btn-dropdown btn-sm px-3" dir="ltr">
            <span className="mx-1">{SupportedCurrencies.find((f) => f.Name === currentCurrency)!.Symbol}</span>
            {currentCurrency}
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      {canChangeCurrency && (
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
          <ul className="navi navi-hover py-4">
            {SupportedCurrencies.map((currency) => (
              <li
                key={currency.Name}
                className={clsx('navi-item', {
                  active: currentCurrency === currency.Name,
                })}>
                <Tooltip title={t(`Currencies.${currency.Name}`)} placement={culture.HtmlDirection === HtmlDirection.LeftToRight ? 'left' : 'right'}>
                  <div onClick={() => changeCurrency(currency.Name)} className="navi-link cursor-pointer">
                    <span className="navi-text" dir="ltr">
                      <span className="mx-2">{currency.Symbol}</span> {currency.Name}
                    </span>
                  </div>
                </Tooltip>
              </li>
            ))}
          </ul>
        </Dropdown.Menu>
      )}
    </Dropdown>
  ) : null;
};

export default CurrencySelectorDropdown;
