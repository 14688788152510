import { Button, Card, Col, Form, Modal, Row, Skeleton } from 'antd';
import React, { useState } from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import { useAppSelector, useHttpCall, useMount } from '../../../../features/hooks';
import { Translations } from '../../../../features/localization';
import { AbpErrorAlert, SimpleDynamicAntdFormElement } from '../../../shared';
import { GetGatewaysItemResponse, PaymentGateway, PaymentGatewayFormElement } from '../../apiTypes';
import { paymentGetDepositGatewaysHttp, paymentGetReserveGatewaysHttp } from '../../http/payment-http';
import { PaymentGatewayLP } from '../../types';
import classes from './ChooseOnlineGateway.module.scss';

type Props = {
  chargeDeposit: boolean;
  currency?: string;
  reserveId?: string;
  onSelect: (data: PaymentGatewayLP) => void;
};
const ChooseOnlineGateway: React.VFC<Props> = React.memo((props) => {
  const { t } = useTranslation();

  const chargeDepositTempState = useAppSelector((state) => state.payment.temp.chargeDeposit);
  const [gatewayGroups, setGatewayGroups] = useState<GetGatewaysItemResponse[]>();
  const [modalData, updateModalData] = useImmer<{ visibility: boolean; gateway?: PaymentGateway; fields: PaymentGatewayFormElement[] }>({
    visibility: false,
    gateway: undefined,
    fields: [],
  });

  const gatewaysApi = useHttpCall(props.chargeDeposit ? paymentGetDepositGatewaysHttp : paymentGetReserveGatewaysHttp);

  const [form] = Form.useForm();

  useMount(() => {
    const data = props.chargeDeposit ? { currencyType: props.currency! } : { reserveId: props.reserveId! };
    gatewaysApi.call<GetGatewaysItemResponse[]>(data).then((response) => {
      if (response && response.success) {
        setGatewayGroups(response.result);
      }
    });
  });

  const handleGatewaySelect = (gateway: PaymentGateway) => {
    if (gateway.form.elements && gateway.form.elements.length > 0) {
      updateModalData((draft) => {
        draft.visibility = true;
        draft.gateway = gateway;
        draft.fields = gateway.form.elements;
      });
    } else {
      props.onSelect({
        gateway: gateway,
        fields: {},
      });
    }
  };

  const handleDynamicFormSubmit = (values: { [key: string]: string }) => {
    props.onSelect({
      gateway: modalData.gateway!,
      fields: values,
    });
  };

  const handleCloseModal = () => {
    updateModalData((draft) => {
      draft.visibility = false;
    });
  };

  return (
    <>
      <Modal
        width={600}
        visible={modalData.visibility}
        closable
        onCancel={handleCloseModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText={t(Translations.Common.Continue)}
        okButtonProps={{ size: 'large' }}
        onOk={() => form.submit()}
        title={modalData.gateway?.name}>
        <Form form={form} onFinish={handleDynamicFormSubmit} labelCol={{ span: 6 }}>
          {modalData.fields.map((item, index) => (
            <SimpleDynamicAntdFormElement
              key={index}
              formInstance={form}
              type={item.type}
              name={item.key}
              defaultValue={item.defaultValue}
              values={item.values}
            />
          ))}
        </Form>
      </Modal>
      <AbpErrorAlert error={gatewaysApi.error} />
      {!gatewaysApi.pending ? (
        <div className="vertical-cards-group">
          {gatewayGroups && gatewayGroups.length > 0 ? (
            gatewayGroups.map((group) => (
              <div key={group.name} className="my-1">
                <Card className={`gateway-group-header box-shadow ${classes.gatewayGroupHeader} ${props.chargeDeposit ? 'no-card' : ''}`}>
                  <Row align="middle" gutter={[10, 0]} wrap={false}>
                    <Col>
                      <img src={group.image.path} alt={group.name} className={classes.gatewayIcon} />
                    </Col>
                    <Col>
                      <h3 className="title-sm mb-0">{group.name}</h3>
                      <span className="text-muted">{group.description}</span>
                    </Col>
                  </Row>
                </Card>
                <Card className={props.chargeDeposit ? 'no-card' : ''}>
                  {group.gateways.map((gateway) => (
                    <Card key={gateway.id} className="antd-card-small-padding">
                      <Row justify="space-between" align="middle">
                        <Col>
                          <img src={gateway.image.path} alt={gateway.name} className={classes.gatewayIcon} />
                          <span className="font-weight-bold mx-2">{gateway.name}</span>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            htmlType="button"
                            onClick={() => handleGatewaySelect(gateway)}
                            size={props.chargeDeposit ? 'middle' : 'large'}
                            className={props.chargeDeposit ? '' : 'min-width-md'}
                            disabled={props.chargeDeposit && chargeDepositTempState.amount <= 1}
                            loading={
                              props.chargeDeposit && chargeDepositTempState.makeToken?.gatewayId === gateway.id && chargeDepositTempState.makeToken.pending
                            }>
                            {t(Translations.Common.Select)}
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </Card>
              </div>
            ))
          ) : (
            <BootstrapAlert variant="warning">
              {t(Translations.Payment.ThereAreNoPaymentGatewaysAvailableRightNow)}
              <br />
              {t(Translations.Support.ContactUsForMoreInformation)}
            </BootstrapAlert>
          )}
        </div>
      ) : (
        <Card>
          <Skeleton active />
        </Card>
      )}
    </>
  );
});

export default ChooseOnlineGateway;
