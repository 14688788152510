import { Button, Col, Row } from 'antd';
import React from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import B2BIcon from '../../../../assets/B2B.svg';
import B2CIcon from '../../../../assets/B2C.svg';
import FlightIcon from '../../../../assets/flight.svg';
import HotelIcon from '../../../../assets/hotel.svg';
import PackageIcon from '../../../../assets/package.svg';
import partner1 from '../../../../assets/partners/partner(1).png';
import partner10 from '../../../../assets/partners/partner(10).png';
import partner11 from '../../../../assets/partners/partner(11).png';
import partner12 from '../../../../assets/partners/partner(12).png';
import partner13 from '../../../../assets/partners/partner(13).png';
import partner14 from '../../../../assets/partners/partner(14).png';
import partner15 from '../../../../assets/partners/partner(15).png';
import partner16 from '../../../../assets/partners/partner(16).png';
import partner17 from '../../../../assets/partners/partner(17).png';
import partner18 from '../../../../assets/partners/partner(18).png';
import partner19 from '../../../../assets/partners/partner(19).png';
import partner2 from '../../../../assets/partners/partner(2).png';
import partner20 from '../../../../assets/partners/partner(20).png';
import partner3 from '../../../../assets/partners/partner(3).png';
import partner4 from '../../../../assets/partners/partner(4).png';
import partner5 from '../../../../assets/partners/partner(5).png';
import partner6 from '../../../../assets/partners/partner(6).png';
import partner7 from '../../../../assets/partners/partner(7).png';
import partner8 from '../../../../assets/partners/partner(8).png';
import partner9 from '../../../../assets/partners/partner(9).png';
import WebApiIcon from '../../../../assets/web-api.svg';
import whiteFlight from '../../../../assets/white-flight.svg';
import whiteHotel from '../../../../assets/white-hotel.svg';
import WhiteLabelIcon from '../../../../assets/white-label.svg';
import whitePackage from '../../../../assets/white-package.svg';
import { useAppSelector } from '../../../../features/hooks';
import { Env } from '../../../../utils/env';
import AuthPageFooter from '../../components/AuthPageFooter/AuthPageFooter';
import AuthPageHeader from '../../components/AuthPageHeader/AuthPageHeader';
import ForgotPassword from '../../components/ForgotPassword';
import Login from '../../components/Login';
import classes from './AuthPage.module.scss';

const AuthPage: React.VFC = () => {
  const isAuthenticated = useAppSelector((state) => state.authentication.isAuthenticated);
  const { path } = useRouteMatch();

  return (
    <>
      {isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <>
          <AuthPageHeader />
          <div className={classes.topBanner}>
            <svg version="1.1" className="mesh-svg" x="0px" y="0px" viewBox="0 0 1800 900">
              <line x1="67.5" y1="48.5" x2="171.5" y2="48.5" />
              <line x1="290.5" y1="48.5" x2="339.5" y2="48.5" />
              <line x1="448.5" y1="48.5" x2="498.5" y2="48.5" />
              <line x1="753.5" y1="48.5" x2="551.5" y2="48.5" />
              <path d="M918.5,48.5c13.3,0,26.5,0,39.8,0" />
              <path d="M1190.5,48.5c33.5,0,67,0,100.5,0" />
              <line x1="1299.5" y1="48.5" x2="1385.5" y2="48.5" />
              <line x1="1401.5" y1="48.5" x2="1403.5" y2="48.5" />
              <line x1="1414.5" y1="48.5" x2="1428.5" y2="48.5" />
              <line x1="1431.5" y1="48.5" x2="1685.5" y2="48.5" />
              <line x1="1690.5" y1="48.5" x2="1709.5" y2="48.5" />
              <line x1="1657.5" y1="149.5" x2="1654.5" y2="149.5" />
              <line x1="1650.5" y1="149.5" x2="1631.5" y2="149.5" />
              <line x1="1166.5" y1="149.5" x2="1599.5" y2="149.5" />
              <path d="M1156.5,149.5c-18,0-36,0-54,0" />
              <line x1="919.5" y1="149.5" x2="960.5" y2="149.5" />
              <line x1="598.5" y1="149.5" x2="727.5" y2="149.5" />
              <line x1="521.5" y1="149.5" x2="495.5" y2="149.5" />
              <line x1="453.5" y1="149.5" x2="8.5" y2="149.5" />
              <line x1="153.5" y1="250.5" x2="377.5" y2="250.5" />
              <line x1="440.5" y1="250.5" x2="515.5" y2="250.5" />
              <line x1="918.5" y1="250.5" x2="853.5" y2="250.5" />
              <line x1="1563.5" y1="250.5" x2="948.5" y2="250.5" />
              <line x1="1568.5" y1="250.5" x2="1577.5" y2="250.5" />
              <line x1="1659.5" y1="250.5" x2="1651.5" y2="250.5" />
              <path id="h1" className="animation" d="M1797.7,352.8c-594.7-0.2-1189.4-0.3-1784.1-0.5" />
              <line x1="188.5" y1="453.5" x2="195.5" y2="453.5" />
              <line x1="204.5" y1="453.5" x2="301.5" y2="453.5" />
              <line x1="319.5" y1="453.5" x2="328.5" y2="453.5" />
              <line x1="364.5" y1="453.5" x2="379.5" y2="453.5" />
              <path d="M482.3,452.5c10.1,0,20.1,0,30.2,0" />
              <line x1="514.5" y1="452.5" x2="555.5" y2="452.5" />
              <line x1="558.5" y1="452.5" x2="570.5" y2="452.5" />
              <line x1="620.5" y1="452.5" x2="636.5" y2="452.5" />
              <line x1="775.5" y1="452.5" x2="1099.5" y2="452.5" />
              <line x1="1513.5" y1="452.5" x2="1109.5" y2="452.5" />
              <line x1="1716.5" y1="452.5" x2="1695.5" y2="452.5" />
              <path d="M1684.5,452.5c-8,0-16,0-24,0" />
              <path d="M1789.3,452.5c-12.9,0-25.9,0-38.8,0" />
              <path id="h2" className="animation" d="M0.7,554.4c599.8,0,1199.5,0,1799.3,0.1" />
              <path d="M42.3,656.5c23.7,0,47.4,0,71.2,0" />
              <line x1="367.5" y1="655.5" x2="628.5" y2="655.5" />
              <line x1="897.5" y1="655.5" x2="1051.5" y2="655.5" />
              <line x1="1429.5" y1="655.5" x2="1435.5" y2="655.5" />
              <line x1="1623.5" y1="655.5" x2="1672.5" y2="655.5" />
              <line x1="1683.5" y1="655.5" x2="1705.5" y2="655.5" />
              <line x1="1686.5" y1="756.5" x2="1474.5" y2="756.5" />
              <line x1="1322.5" y1="756.5" x2="1274.5" y2="756.5" />
              <line x1="1096.5" y1="756.5" x2="1073.5" y2="756.5" />
              <line x1="1027.5" y1="756.5" x2="909.5" y2="756.5" />
              <path d="M795.9,756.5c-2.5,0-4.9,0-7.4,0" />
              <line x1="573.5" y1="756.5" x2="435.5" y2="756.5" />
              <line x1="432.5" y1="858.5" x2="492.5" y2="858.5" />
              <line x1="1664.5" y1="858.5" x2="1603.5" y2="858.5" />
              <line x1="50.5" y1="120.5" x2="50.5" y2="184.5" />
              <path d="M50.5,569.6c0,21.6,0,43.2,0,64.9" />
              <line x1="50.5" y1="647.5" x2="50.5" y2="658.5" />
              <line x1="155.5" y1="37.5" x2="155.5" y2="74.5" />
              <line x1="155.5" y1="23.5" x2="155.5" y2="20.5" />
              <line x1="155.5" y1="149.5" x2="155.5" y2="242.5" />
              <line x1="155.5" y1="257.5" x2="155.5" y2="246.5" />
              <line x1="155.5" y1="280.5" x2="155.5" y2="287.5" />
              <line x1="155.5" y1="370.5" x2="155.5" y2="389.5" />
              <line x1="155.5" y1="391.5" x2="155.5" y2="396.5" />
              <line x1="155.5" y1="522.5" x2="155.5" y2="526.5" />
              <line x1="155.5" y1="544.5" x2="155.5" y2="541.5" />
              <line x1="155.5" y1="569.5" x2="155.5" y2="576.5" />
              <path d="M155.5,609.5c0,13.2,0,26.4,0,39.5" />
              <path id="v1" className="animation" d="M258.8,900c0.2-299.8,0.5-599.7,0.7-899.5" />
              <line x1="365.5" y1="5.5" x2="365.5" y2="37.5" />
              <line x1="363.5" y1="63.5" x2="363.5" y2="115.5" />
              <line x1="363.5" y1="134.5" x2="363.5" y2="214.5" />
              <line x1="363.5" y1="248.5" x2="363.5" y2="450.5" />
              <line x1="363.5" y1="491.5" x2="363.5" y2="496.5" />
              <line x1="363.5" y1="572.5" x2="363.5" y2="578.5" />
              <line x1="467.5" y1="895.5" x2="467.5" y2="562.5" />
              <line x1="467.5" y1="355.5" x2="467.5" y2="326.5" />
              <line x1="467.5" y1="208.5" x2="467.5" y2="323.5" />
              <line x1="467.5" y1="177.5" x2="467.5" y2="186.5" />
              <line x1="467.5" y1="124.5" x2="467.5" y2="79.5" />
              <line x1="467.5" y1="63.5" x2="467.5" y2="44.5" />
              <line x1="467.5" y1="30.5" x2="467.5" y2="37.5" />
              <line x1="571.5" y1="9.5" x2="571.5" y2="58.5" />
              <line x1="571.5" y1="357.5" x2="571.5" y2="360.5" />
              <line x1="571.5" y1="454.5" x2="571.5" y2="474.5" />
              <path d="M571.4,478.1c0,10.5,0.1,20.9,0.1,31.4" />
              <line x1="571.5" y1="531.5" x2="571.5" y2="534.5" />
              <line x1="571.5" y1="631.5" x2="571.5" y2="759.5" />
              <line x1="676.5" y1="6.5" x2="676.5" y2="179.5" />
              <line x1="676.5" y1="530.5" x2="676.5" y2="533.5" />
              <line x1="676.5" y1="573.5" x2="676.5" y2="575.5" />
              <path d="M676.5,883.5c0-20.7,0-41.5,0-62.2" />
              <line x1="780.5" y1="780.5" x2="780.5" y2="776.5" />
              <line x1="780.5" y1="595.5" x2="780.5" y2="436.5" />
              <line x1="780.5" y1="403.5" x2="780.5" y2="392.5" />
              <line x1="780.5" y1="373.5" x2="780.5" y2="370.5" />
              <line x1="780.5" y1="313.5" x2="780.5" y2="291.5" />
              <path id="v2" className="animation" d="M885.5,1.5c0.4,298.1,0.8,596.2,1.2,894.2" />
              <line x1="989.5" y1="804.5" x2="989.5" y2="446.5" />
              <line x1="989.5" y1="385.5" x2="989.5" y2="413.5" />
              <line x1="989.5" y1="331.5" x2="989.5" y2="189.5" />
              <line x1="989.5" y1="157.5" x2="989.5" y2="182.5" />
              <line x1="989.5" y1="37.5" x2="989.5" y2="34.5" />
              <line x1="989.5" y1="14.5" x2="989.5" y2="19.5" />
              <line x1="1093.5" y1="334.5" x2="1093.5" y2="149.5" />
              <line x1="1093.5" y1="135.5" x2="1093.5" y2="142.5" />
              <line x1="1093.5" y1="379.5" x2="1093.5" y2="548.5" />
              <line x1="1093.5" y1="564.5" x2="1093.5" y2="609.5" />
              <line x1="1093.5" y1="708.5" x2="1093.5" y2="765.5" />
              <line x1="1302.5" y1="45.5" x2="1302.5" y2="526.5" />
              <line x1="1302.5" y1="750.5" x2="1302.5" y2="761.5" />
              <line x1="1302.5" y1="668.5" x2="1302.5" y2="674.5" />
              <path d="M1302.5,737.5c0-4.4,0-8.9-0.1-13.3c0-9.8-0.1-19.6-0.2-29.3" />
              <line x1="1406.5" y1="628.5" x2="1406.5" y2="609.5" />
              <line x1="1406.5" y1="589.5" x2="1406.5" y2="576.5" />
              <line x1="1406.5" y1="548.5" x2="1406.5" y2="37.5" />
              <line x1="1510.5" y1="25.5" x2="1510.5" y2="373.5" />
              <line x1="1510.5" y1="442.5" x2="1510.5" y2="461.5" />
              <line x1="1510.5" y1="438.5" x2="1510.5" y2="432.5" />
              <line x1="1510.5" y1="569.5" x2="1510.5" y2="573.5" />
              <path d="M1510.5,672.5c0-19,0-38,0-57" />
              <line x1="1510.5" y1="739.5" x2="1510.5" y2="830.5" />
              <line x1="1614.5" y1="872.5" x2="1614.5" y2="717.5" />
              <line x1="1517.5" y1="655.5" x2="1521.5" y2="655.5" />
              <line x1="1614.5" y1="697.5" x2="1614.5" y2="693.5" />
              <line x1="1614.5" y1="651.5" x2="1614.5" y2="637.5" />
              <line x1="1614.5" y1="551.5" x2="1614.5" y2="538.5" />
              <path d="M1614.5,472.5c0,13.7,0.1,27.4,0.1,41.1" />
              <line x1="1614.5" y1="336.5" x2="1614.5" y2="327.5" />
              <line x1="1614.5" y1="135.5" x2="1614.5" y2="6.5" />
              <line x1="1614.5" y1="515.5" x2="1614.5" y2="518.5" />
              <line x1="1718.5" y1="397.5" x2="1718.5" y2="400.5" />
              <path d="M1718.7,407.1c-0.1,11.1-0.1,22.3-0.2,33.4" />
              <line x1="1718.5" y1="453.5" x2="1718.5" y2="455.5" />
              <path d="M1718.5,562.5c0-28.3,0-56.7,0-85" />
              <line x1="1718.5" y1="584.5" x2="1718.5" y2="582.5" />
              <line x1="1718.5" y1="597.5" x2="1718.5" y2="594.5" />
              <path id="v3" className="animation" d="M1197.5,3.5c0.4,298.1,0.8,596.2,1.2,894.2" />
            </svg>
            <div className="page-container">
              <Row justify="space-between" align="middle">
                <Col xs={{ span: 24, order: 2 }} lg={{ span: 9, order: 1 }}>
                  <div className={classes.formHolder}>
                    <div className={'d-none'}>
                      <Switch>
                        <Route path={`${path}/login`}>
                          <Login />
                        </Route>
                        <Route path={`${path}/forgot-password`}>
                          <ForgotPassword />
                        </Route>
                        <Redirect exact from={`${path}`} to={`${path}/login`} />
                        <Redirect to={`${path}/login`} />
                      </Switch>
                    </div>
                    <Login />
                  </div>
                </Col>
                <Col span={12} xs={{ span: 24, order: 1 }} lg={{ span: 13, order: 2 }} className="px-3">
                  <h3 className="font-size-h1 mb-0 mb-lg-5 text-white">Welcome to {Env.TenantHost === 'AyanAir' ? 'Ayan Air' : 'iTours'}!</h3>
                  <p className="font-weight-lighter text-white opacity-80">The best and fastest way to book your next trip.</p>
                </Col>
                <Col dir="rtl" xs={{ span: 0, order: 3 }} lg={{ span: 2 }}>
                  <div className={classes.bannerIconItem}>
                    <img src={whiteFlight} alt={'flight'} />
                    <div>Flight</div>
                  </div>
                  <div className={classes.bannerIconItem}>
                    <img src={whiteHotel} alt={'hotel'} />
                    <div>Hotel</div>
                  </div>
                  <div className={classes.bannerIconItem}>
                    <img src={whitePackage} alt={'package'} />
                    <div>Dynamic Package</div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {Env.TenantHost === 'iTours' && (
            <div className={`py-5 py-lg-15 ${classes.darkBg}`}>
              <div className="page-container">
                <Row justify={'space-between'} align={'middle'} gutter={[10, 10]}>
                  <Col>
                    <h4 className={'title-lg text-light mb-0'}>Register now to join our clients and enjoy world wide deals</h4>
                  </Col>
                  <Col>
                    <Link to={'/auth/signUp'}>
                      <Button type={'primary'} size="large" danger>
                        Register Now
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          <div className="py-15 bg-white ">
            <div className="page-container">
              <div>
                <h3 className="title-lg">Our Platform and Services is an All-In-One solution.</h3>
                <p>
                  To make the most of a great software application, it is crucial that the right services are also provided. With over 22 different suppliers,
                  including all major GDSs (Amadeus, Galileo, Sabre), NDC, LCCs, hotels, transfers, trains, cruises. The platform is an All-In-One solution for
                  all the technical needs any given Travel Agency can have. {Env.TenantHost === 'AyanAir' ? 'Ayan Air' : 'ITours'} accompanies you along your
                  transformation journey and provides you with industry, technology and business services to make sure you get the most of your investment.
                </p>
              </div>
            </div>
          </div>

          <div className={classes.servicesSection}>
            <div className="page-container">
              <h2 className={'title-lg text-light'}>WE COVER ALL TRAVEL SERVICES</h2>
              <p>Our services are available for both leisure and corporate travel.</p>
              <Row align={'middle'} justify={'center'} gutter={[20, 20]} className={'mt-10'}>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={FlightIcon} className={classes.icon} alt="Flight" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>Flight</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={HotelIcon} className={classes.icon} alt="Hotel" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>Hotel</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={PackageIcon} className={classes.icon} alt="Package" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>Package</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={B2BIcon} className={classes.icon} alt="B2B Selling Platform" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>B2B Selling Platform</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={B2CIcon} className={classes.icon} alt="B2C Booking System" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>B2C Booking System</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={WhiteLabelIcon} className={classes.icon} alt="White Label" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>White Label</h3>
                  </div>
                </Col>
                <Col>
                  <div className={classes.serviceItemHolder}>
                    <img src={WebApiIcon} className={classes.icon} alt="API" />
                    <h3 className={'title-xs mb-0 mt-2 px-2'}>API</h3>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/*<div className={`py-15 ${classes.darkBg}`}>*/}
          {/*  <div className="page-container">*/}
          {/*    <Row gutter={[60, 60]}>*/}
          {/*      <Col span={8} className="text-center">*/}
          {/*        <h4 className={'title-md'}>Cheap Flights to Over 15,000 Destinations</h4>*/}
          {/*        <p>*/}
          {/*          Fly around the world with itours. Book your airline tickets to over 15,000 destinations including exciting places like Dubai, Vienna and*/}
          {/*          Madrid with itours now and travel at affordable rates.*/}
          {/*        </p>*/}
          {/*      </Col>*/}
          {/*      <Col span={8} className="text-center">*/}
          {/*        <h4 className={'title-md'}>Super Easy Flight Search</h4>*/}
          {/*        <p>*/}
          {/*          Flight bookings are easy and hassle-free with itours. Compare flight ticket options and airlines, to book cheap flights as a return,*/}
          {/*          one-way or multi-city journey, all at the click of a button.*/}
          {/*        </p>*/}
          {/*      </Col>*/}
          {/*      <Col span={8} className="text-center">*/}
          {/*        <h4 className={'title-md'}>Discounts and the Cheapest Flights </h4>*/}
          {/*        <p>*/}
          {/*          Compare airline ticket prices between different airlines to find deals, as you book the cheapest flight tickets for all types of cabins,*/}
          {/*          from economy to business class and first class.*/}
          {/*        </p>*/}
          {/*      </Col>*/}
          {/*      <Col span={8} className="text-center">*/}
          {/*        <h4 className={'title-md'}>Compare More Than 1 Million Hotels</h4>*/}
          {/*        <p>*/}
          {/*          Book a comfortable, cheap hotel with itours. Compare over 1 million properties from all over the world, find the perfect stay, and book*/}
          {/*          hotels online in a few simple steps.*/}
          {/*        </p>*/}
          {/*      </Col>*/}
          {/*      <Col span={8} className="text-center">*/}
          {/*        <h4 className={'title-md'}>Easy to Use Hotel Search</h4>*/}
          {/*        <p>*/}
          {/*          Use our online hotel booking website to easily find and compare the best hotels. With more than 1 million hotels on offer, you can compare*/}
          {/*          prices, amenities and the official star rating*/}
          {/*        </p>*/}
          {/*      </Col>*/}
          {/*      <Col span={8} className="text-center">*/}
          {/*        <h4 className={'title-md'}>Discounts and the Cheapest Hotels</h4>*/}
          {/*        <p>*/}
          {/*          Online hotel booking with itours lets you compare prices for all different types of hotels and amenities you could possibly want, for a*/}
          {/*          budget-friendly rate.*/}
          {/*        </p>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={`${classes.partnersSection} bg-white py-15`}>
            <div className={'page-container'}>
              <h3 className="title-lg text-center">Our Partners</h3>
              <Row gutter={[25, 25]} justify={'center'} align={'middle'}>
                <Col>
                  <img src={partner1} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner2} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner3} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner4} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner5} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner6} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner7} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner8} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner9} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner10} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner11} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner12} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner13} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner14} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner15} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner16} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner17} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner18} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner19} alt="Logo" className={classes.partnerLogo} />
                </Col>
                <Col>
                  <img src={partner20} alt="Logo" className={classes.partnerLogo} />
                </Col>
              </Row>
            </div>
          </div>
          <AuthPageFooter />
        </>
      )}
    </>
  );
};

export default AuthPage;
