import { Button, Col, Form, Input, Row, notification } from 'antd';
import React from 'react';

import Dibs from '../../../../assets/partners/white/Dibs.png';
import Giata from '../../../../assets/partners/white/Giata.png';
import IATA from '../../../../assets/partners/white/Iata.png';
import Mastercard from '../../../../assets/partners/white/Mastercard.png';
import Visa from '../../../../assets/partners/white/Visa.png';
import { useAntdValidation, useHttpCall } from '../../../../features/hooks';
import { Env } from '../../../../utils/env';
import { AbpErrorAlert } from '../../../shared';
import { identityNewsLetterCreateHttp } from '../../http/authentication-http';
import classes from './AuthPageFooter.module.scss';

type FormValues = {
  email: string;
};
const AuthPageFooter: React.FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { labelWithRules } = useAntdValidation(form);

  const newsLetterCreateApi = useHttpCall(identityNewsLetterCreateHttp);

  const handleSubmit = (values: FormValues) => {
    newsLetterCreateApi.call({ email: values.email }).then((response) => {
      if (response && response.success) {
        notification.success({
          message: 'Submitted successfully',
        });
        form.setFieldsValue({
          email: '',
        });
      }
    });
  };

  return (
    <div className={classes.loginFooter}>
      <div>
        <div className={'page-container text-center'}>
          <div className={`${classes.topFooterInner} mt-10`}>
            <Row gutter={[20, 10]} justify={'center'} align={'middle'}>
              <Col>
                <img src={Mastercard} alt="Logo" className={classes.partnerLogo} />
              </Col>
              <Col>
                <img src={Visa} alt="Logo" className={classes.partnerLogo} />
              </Col>
              <Col>
                <img src={Dibs} alt="Logo" className={classes.partnerLogo} />
              </Col>
              <Col>
                <img src={Giata} alt="Logo" className={classes.partnerLogo} />
              </Col>
              <Col>
                <img src={IATA} alt="Logo" className={classes.partnerLogo} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className={'page-container'}>
        <div className={'text-center'}>
          <h4 className={'title-md mt-10 mb-2 color-light text-light'}>Newsletter subscription</h4>
          <p className={'text-light'}>Sign up for our newsletter to get special offers</p>
        </div>
        <Form form={form} className={classes.newsletterForm} name="newsletter" onFinish={handleSubmit}>
          <AbpErrorAlert error={newsLetterCreateApi.error} />
          <Row justify={'center'} wrap={false}>
            <Col flex={'auto'}>
              <Form.Item name="email" {...labelWithRules({ fieldName: 'Email', rules: [{ type: 'Required' }, { type: 'Email' }] })}>
                <Input type="email" dir="ltr" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={newsLetterCreateApi.pending}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className={classes.bottomFooter}>
        <div className={'page-container'}>
          <div className="order-2 order-sm-1 text-center">
            &copy; {new Date(Date.now()).getFullYear()} {Env.TenantHost === 'AyanAir' ? 'Ayan Air' : 'iTours'}, all rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPageFooter;
