import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AyanLogo from '../../../../assets/ayanAir-logo.svg';
import Logo from '../../../../assets/circleLogo.svg';
import { Translations } from '../../../../features/localization';
import { AbpError } from '../../../../types';
import { Env } from '../../../../utils/env';
import { AbpErrorAlert } from '../../index';

type Props = {
  visible: boolean;
  closable: boolean;
  onCancel?: () => void;
  error?: AbpError;
  mainMessage?: string;
  showPleaseWaitMessage?: boolean;
  logoLoading?: boolean;
  width?: string | number;
};
const LoadingModal: React.VFC<Props> = (props) => {
  const { t } = useTranslation();
  const loadingLogo = Env.TenantHost === 'AyanAir' ? AyanLogo : Logo;
  return (
    <Modal
      width={props.width || 520}
      centered
      visible={props.visible}
      closable={props.closable}
      footer={null}
      className="text-center"
      onCancel={props.onCancel}>
      {props.error ? (
        <div className="my-10">
          <AbpErrorAlert error={props.error} />
        </div>
      ) : (
        <div className={'my-10'}>
          {props.logoLoading ? (
            <>
              <div className="logoLoading mb-5">
                <img src={loadingLogo} alt={'logo'} title={'logo'} />
              </div>
              {props.mainMessage && <div className={'bold'}>{props.mainMessage}</div>}
            </>
          ) : (
            <>
              <Spin className={'mb-5'} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
              {props.mainMessage && <div>{props.mainMessage}</div>}
              {props.showPleaseWaitMessage && <div>{t(Translations.Common.PleaseWait)}</div>}
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default LoadingModal;
